import React, { useState} from 'react';
import { Layout, Menu, theme } from 'antd';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import CustomHeader from './components/CustomHeader';
import Home from './pages/Home';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Keycloak from 'keycloak-js';
import { httpClient } from './HttpClient';
import keycloak from './config.json';
import SidebarData from './components/SidebarData';

const {Sider, Content } = Layout;


let initOptions = {
  url: keycloak.keycloak.url,
  realm: keycloak.keycloak.realm,
  clientId: keycloak.keycloak.clientId,
}



let kc = new Keycloak(initOptions);

kc.init({
  onLoad: 'login-required', // Supported values: 'check-sso' , 'login-required'
  checkLoginIframe: true,
  pkceMethod: 'S256'
}).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    /* Remove below logs if you are using this on production */
    console.info("Authenticated");
    console.log('auth', auth)
    console.log('Keycloak', kc)
    console.log('Access Token', kc.token)
    

    /* http client will use this header in every request it sends */
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${kc.token}`;

    kc.onTokenExpired = () => {
      console.log('token expired')
    }
  }
}, () => {
  /* Notify the user if necessary */
  console.error("Authentication Failed");
});


function App() {
  
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  return ( 
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={SidebarData} />
        </Sider>
        <Layout>
        <CustomHeader kc={kc} 
        collapsed={collapsed} onToggle={() => setCollapsed(!collapsed)} />
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              height: '100%',
             background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/page1" element={<Page1 kc={kc}/>} />
              <Route path="/page2" element={<Page2 />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}


export default App;
