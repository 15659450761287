import axios from 'axios';

// Funkcja pobierająca dane kart
export const fetchCards = async (token) => {
  try {
    const response = await axios.get('http://srv20.mikr.us:20133/api/v1/cards', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Zwracanie danych
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rzucenie błędu, by móc obsłużyć go w miejscu, gdzie wywołujesz fetchCards
  }
};
