import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { fetchCards } from '../api/cardService'; // Import funkcji z oddzielnego pliku

// Kolumny tabeli
const columns = [
  {
    Header: 'Id',
    accessor: 'id', 
  },
  {
    Header: 'Pan',
    accessor: 'pan',
  },
  {
    Header: 'Status',
    accessor: 'state',
  },
];

const BasicTable = ({ token }) => {
  const [data, setData] = useState([]); // Zmienna stanu na dane
  const [loading, setLoading] = useState(true); // Zmienna stanu do śledzenia ładowania

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cards = await fetchCards(token); // Wywołanie funkcji pobierającej dane
        setData(cards); // Ustaw dane do stanu
      } catch (error) {
        console.error('Error during fetch:', error); // Obsłuż błędy (możesz dodać np. komunikat dla użytkownika)
      } finally {
        setLoading(false); // Zakończ ładowanie
      }
    };

    fetchData(); // Wywołanie funkcji pobierającej dane
  }, [token]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  if (loading) {
    return <div>Loading...</div>; // Wyświetl komunikat podczas ładowania
  }

  return (
    <table {...getTableProps()} style={{ border: '1px solid black', width: '100%', textAlign: 'left' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} key={column.id} style={{ padding: '10px', borderBottom: '1px solid black' }}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row.id}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} key={cell.column.id} style={{ padding: '10px', borderBottom: '1px solid black' }}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default BasicTable;
