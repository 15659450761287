import React, { useEffect, useState } from 'react';
import { Col, Row,Select } from 'antd';
import { fetchMarkOptions } from '../api/markApi';
import BasicTable  from '../components/Table';

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function Page1({ kc }) {


  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      const result = await fetchMarkOptions(kc.token);
      setOptions(result);
    };

    getOptions();
  }, []);


    return <>  <Row>
    <Col >
    <Select
            defaultValue="PL"
            style={{ width: 120 }}
            onChange={handleChange}
            options={options}
          />
    
    </Col>
    <Col >Tutaj będą kolejne selecty i buttony</Col>
  </Row>
  <Row span={24}>
   
  <BasicTable token={kc.token}></BasicTable>
    
  </Row>
  </>
  }

  export default Page1;